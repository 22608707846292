export const isTableauUrl = (url: string) => {
  const { hostname, pathname, search } = new URL(url);

  if (hostname !== 'public.tableau.com') {
    return false;
  }

  const pathnameParts = pathname.split('/');

  if (pathnameParts[1] !== 'views') {
    return false;
  }

  if (!search.includes('%3AshowVizHome=no')) {
    return false;
  }

  return true;
};
