import { datawrapperDomain } from './constants';

export const isDatawrapperUrl = (url: string) => {
  const { hostname, pathname } = new URL(url);

  if (!datawrapperDomain.includes(hostname)) {
    return false;
  }

  const pathnameParts = pathname.split('/');

  if (pathnameParts.length < 2) {
    return false;
  }

  const [, id, version] = pathnameParts;

  if (!id || !version) {
    return false;
  }

  return true;
};
