import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { createYoutubeEmbedUrl, isVerticalYoutubeVideo } from './utils';

interface Props {
  src: string;
  title?: string;
}

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'block',
    position: 'relative',
    aspectRatio: '16/9',
  },
  verticalContainer: {
    aspectRatio: '9/16',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      margin: '0 auto',
    },
  },
  iframe: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
}));

export const YoutubeCard: React.FC<Props> = ({ src, title }) => {
  const { classes, cx } = useStyles();
  const embedUrl = createYoutubeEmbedUrl(src);

  if (!embedUrl) {
    return null;
  }

  const isVertical = isVerticalYoutubeVideo(src);

  return (
    // div breaks ssr when used inside "p" tag
    <span
      className={cx(classes.container, {
        [classes.verticalContainer]: isVertical,
      })}
    >
      <iframe
        allowFullScreen
        src={embedUrl}
        title={title}
        className={classes.iframe}
      />
    </span>
  );
};
