import React from 'react';

import { Helmet } from 'react-helmet-async';

import { OPTIONS, TABLEAU_API_230 } from './constants';

interface Props {
  href: string;
}

const Tableau: React.FC<Props> = ({ href }) => {
  const containerRef = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    const containerEl = containerRef.current;

    if (!containerEl || !global.tableau) {
      return;
    }

    // eslint-disable-next-line no-new
    new global.tableau.Viz(containerEl, href, OPTIONS);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <script src={TABLEAU_API_230} defer />
      </Helmet>
      <span data-test-locator="Tableau graph" ref={containerRef} />
    </React.Fragment>
  );
};

export { Tableau };
