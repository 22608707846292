export const isPlotlyUrl = (url: string) => {
  const { hostname, pathname } = new URL(url);

  if (hostname !== 'plot.ly') {
    return false;
  }

  const pathnameParts = pathname.split('/');

  if (pathnameParts.length < 3) {
    return false;
  }

  const [, account, id] = pathnameParts;

  if (!account || !id || !id.endsWith('.embed')) {
    return false;
  }

  return true;
};

export const isInfogramUrl = (url: string) => {
  const { hostname, pathname, searchParams } = new URL(url);

  if (hostname !== 'e.infogram.com') {
    return false;
  }

  const pathnameParts = pathname.split('/');

  if (pathnameParts.length < 2) {
    return false;
  }

  const [, id] = pathnameParts;

  if (!id) {
    return false;
  }

  if (searchParams.get('src') !== 'embed') {
    return false;
  }

  return true;
};

export const removePlotlyEditButton = (url: string) => {
  const urlObj = new URL(url);

  urlObj.searchParams.set('link', 'false');

  return urlObj.toString();
};
