export const isSpotifyEpisode = (url: string) => {
  const { hostname, pathname } = new URL(url);

  if (hostname !== 'open.spotify.com') {
    return false;
  }

  const pathnameParts = pathname.split('/');

  if (pathnameParts.length < 3) {
    return false;
  }

  if (pathnameParts[1] !== 'episode') {
    return false;
  }

  const episodeId = pathnameParts[2];

  if (!episodeId) {
    return false;
  }
  return true;
};

export const createEmbedUrl = (url: string) => {
  const urlObj = new URL(url);
  const episodeId = urlObj.pathname.split('/')[2];

  urlObj.pathname = `/embed/episode/${episodeId}`;

  return urlObj.toString();
};
