import React from 'react';

import { parse } from 'url';

import { Link } from '@hbf/dsl/legacy';

import { fixHref } from 'ha/utils/urls/fixHref';
import { isExternalUrl } from 'ha/utils/urls/isExternalUrl';
import { isInternalWithSubdomain } from 'ha/utils/urls/isInternalWithSubdomain';
import { isValidUrl } from 'ha/utils/urls/isValidUrl';

import { isDatawrapperUrl, Datawrapper } from './Datawrapper';
import { isInstagramUrl, InstagramCard } from './InstagramCard';
import { isPlotlyUrl, isInfogramUrl, Plotly } from './Plotly';
import { isSpotifyEpisode, SpotifyCard } from './SpotifyCard';
import { isTableauUrl, Tableau } from './Tableau';
import { isYoutubeUrl, YoutubeCard } from './YoutubeCard';

interface Props {
  children: React.ReactNode;
  href: string;
  title?: string;
}

// This is only for tenant, advertiser, country and city types
// If the embedded link needs to be added to press and landing types,
// you'll need to add it to src/ui.legacy/Markdown/MarkdownContent.js
const MarkdownLink: React.FC<Props> = ({ children, href, title }) => {
  const text = Array.isArray(children) ? children[0] : children;

  if ((!text || text === href) && isValidUrl(href)) {
    if (isYoutubeUrl(href)) {
      return <YoutubeCard src={href} title={title} />;
    }
    if (isInstagramUrl(href)) {
      return <InstagramCard src={href} title={title} />;
    }
    if (isTableauUrl(href)) {
      return <Tableau href={href} />;
    }
    if (isPlotlyUrl(href) || isInfogramUrl(href)) {
      return <Plotly src={href} title={title} />;
    }
    if (isSpotifyEpisode(href)) {
      return <SpotifyCard src={href} title={title} />;
    }
    if (isDatawrapperUrl(href)) {
      return <Datawrapper src={href} title={title} />;
    }
  }

  if (href.includes('mailto:')) {
    return (
      <Link to={href} kind="mint-underline" target="_blank">
        {children}
      </Link>
    );
  }

  const fixedHref = fixHref(href);

  if (isExternalUrl(href) || isInternalWithSubdomain(href)) {
    return (
      <Link to={fixedHref} target="_blank" kind="mint-underline">
        {children}
      </Link>
    );
  }

  const parsedHref = parse(fixedHref);

  return (
    <Link
      to={`${parsedHref.pathname}${parsedHref.search || ''}`}
      kind="mint-underline"
      target="_blank"
    >
      {children}
    </Link>
  );
};

export { MarkdownLink };
