import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { isReel, createEmbedUrl } from './utils';

interface Props {
  src: string;
  title?: string;
}

const useStyles = makeStyles()(() => ({
  iframe: {
    margin: '0 auto',
    width: '100%',
    height: '100%',
    border: 'none',
    aspectRatio: '9/9.6',
  },
  reel: {
    aspectRatio: '9/11.9',
  },
}));

export const InstagramCard: React.FC<Props> = ({ src, title }) => {
  const { classes, cx } = useStyles();

  return (
    <iframe
      title={title}
      src={createEmbedUrl(src)}
      className={cx(classes.iframe, isReel(src) && classes.reel)}
    />
  );
};
