import { instagramDomains } from './constants';

export const isInstagramUrl = (url: string) => {
  const { hostname, pathname } = new URL(url);

  if (!instagramDomains.includes(hostname)) {
    return false;
  }

  const [, postType, postId] = pathname.split('/');

  if (!postType || !postId) {
    return false;
  }

  return true;
};

export const createEmbedUrl = (url: string) => {
  const urlObj = new URL(url);

  if (!urlObj.pathname.endsWith('/')) {
    urlObj.pathname = `${urlObj.pathname}/`;
  }

  urlObj.pathname = `${urlObj.pathname}embed`;

  return urlObj.toString();
};

export const isReel = (url: string) => {
  const { pathname } = new URL(url);

  const [, postType] = pathname.split('/');

  return postType === 'reel';
};
