/**
 * Checks if a given URL is valid.
 *
 * @example
 * const url = 'https://www.google.com';
 * const isValid = isValidUrl(url); // true
 */
export const isValidUrl = (url: string) => {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch {
    return false;
  }
};
