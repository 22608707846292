import {
  isVerticalYoutubeVideoParam,
  youtubeDomains,
  youtubeEmbedUrl,
} from './constants';

export const isYoutubeUrl = (url: string) => {
  const { hostname, pathname } = new URL(url);

  if (!youtubeDomains.includes(hostname)) {
    return false;
  }

  const pathnameParts = pathname.split('/');

  if (pathnameParts.length < 2) {
    return false;
  }

  return true;
};

export const isYoutubeShorts = (pathname: string) => {
  return pathname.includes('shorts');
};

export const createYoutubeEmbedUrl = (url: string) => {
  const { hostname, searchParams, pathname } = new URL(url);

  let videoId = null;

  if (isYoutubeShorts(pathname)) {
    // Example: 'https://www.youtube.com/shorts/AmZThGLDR_c',
    // eslint-disable-next-line prefer-destructuring
    videoId = pathname.split('/')[2];
  } else if (hostname.includes('youtube')) {
    // Example: 'http://www.youtube.com/watch?v=t1pqi8vjTLY',
    videoId = searchParams.get('v');
  } else if (hostname.includes('youtu.be')) {
    // Example: 'https://www.youtu.be/t1pqi8vjTLY',
    videoId = pathname.slice(1);
  }

  if (!videoId) {
    return null;
  }

  return `${youtubeEmbedUrl}${videoId}`;
};

export const isVerticalYoutubeVideo = (url: string) => {
  const { pathname, searchParams } = new URL(url);
  return (
    isYoutubeShorts(pathname) ||
    searchParams.get(isVerticalYoutubeVideoParam) === 'true'
  );
};
