export const youtubeDomains = [
  'youtube.com',
  'www.youtube.com',
  'm.youtube.com',
  'www.youtu.be',
  'youtu.be',
];

export const youtubeEmbedUrl = 'https://www.youtube.com/embed/';

export const isVerticalYoutubeVideoParam = 'isVertical';
